<template>
  <div class="content-component" @click="handleClearSelection">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('System Open Form Types')"
    />
    <el-button
      v-show="can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      @click.stop="createContentItem"
      >{{ __("Create Open Formtype") }}</el-button
    >
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :content-api="contentAPI"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar v-loading="loading" :native="true">
          <div class="contentCardsContainer">
            <el-row
              type="flex"
              justify="start"
              class="list-cards"
              v-if="openFormTypes.length"
              v-show="can('content.' + additionalValidateRoute + '.read')"
            >
              <ContentCard
                v-for="(openFormType, index) in openFormTypes"
                :key="'_content_open_form_type_' + index"
                :draggable-data="{
                  content_id: selectedItems.length
                    ? selectedIds
                    : [openFormType[primaryKey]],
                  content_type: contentType
                }"
                :allow-multi-select="false"
                :card-class="{ selected: selectedItemIndex === index }"
                icon="icon-open-form-grey.svg"
                :card-text="openFormType.oft_name"
                @delete="
                  handleDelete(
                    openFormType,
                    'OpenFormType',
                    openFormType.open_form_type_id
                  )
                "
                @select="handleSelect(index, openFormType)"
                @edit="handleEdit(index, openFormType)"
              ></ContentCard>
            </el-row>
            <el-row
              v-else-if="!loading"
              style="padding-top: 100px;"
              type="flex"
              justify="center"
            >
              No Open Forms
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentCard from "@/views/build/content/mixins/ContentCard";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PageHeader from "@/components/PageHeader";

import { EventBus } from "@/EventBus";
import _ from "lodash";

export default {
  name: "OpenFormTypesList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentCard,
    PageHeader
  },
  selectedItemIndex: {
    required: false,
    default: null
  },
  data() {
    return {
      additionalValidateRoute: "open-forms",
      sortByColumns: ["oft_name", "open_form_type_id"],
      isContent: true,
      forceDeleteEventName: "initiate-force-delete-OpenFormType",
      safeDeleteEventName: "initiate-safe-delete-OpenFormType",
      contentUsages: [],
      checkInUseDialog: false,
      defaultProps: {
        children: "children",
        label: "label"
      },
      showDeleteConfirmationDialog: false
    };
  },

  computed: {
    ...mapState("openFormTypes", {
      openFormTypes: state => state.openformtypes,
      loading: state => state.loading
    }),
    selectedIds() {
      return _.map(this.selectedItems, this.primaryKey);
    }
    /*...mapState("folders", {
      checkInUseLoading: state => state.checkInUseLoading
    }),
    contentUsageCount() {
      return _.size(this.contentUsages);
    },*/
  },

  methods: {
    ...mapActions("openFormTypes", {
      contentAPI: "getSystemOpenFormTypes",
      deleteContentMethod: "deleteSystemOpenFormType",
      undoDeleteContent: "undoDeleteOpenFormType",
      safeDeleteContent: "safeDeleteOpenFormType"
    })
  },
  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });
    EventBus.$on(this.safeDeleteEventName, ({ content }) => {
      this.initiateSafeDelete(content);
    });
  },
  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
    EventBus.$off(this.safeDeleteEventName);
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: #4db3f6 !default;
$content-theme-hover-color: dodgerblue !default;
/*cd0054*/
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";
::v-deep .el-select {
  input {
    height: 44px;
    border-color: #f5f5f8;
    color: #a0a8b5;
  }

  width: 90px;
  .el-input.is-focus .el-input__inner {
    border-color: var(--theme-color) !important;
  }

  .el-input__inner:hover {
    border-color: var(--theme-color) !important;
  }

  .el-select-dropdown__item.selected {
    color: var(--theme-color) !important;
  }

  .el-input__inner:focus {
    border-color: var(--theme-color) !important;
  }
}
</style>
