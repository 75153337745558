var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-component",on:{"click":_vm.handleClearSelection}},[_c('page-header',{staticClass:"absolute",staticStyle:{"padding":"20px 30px","width":"calc(100% - 335px)"},attrs:{"title":_vm.__('System Open Form Types')}}),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.can('content.' + _vm.additionalValidateRoute + '.write')),expression:"can('content.' + additionalValidateRoute + '.write')"}],staticClass:"createBtn",on:{"click":function($event){$event.stopPropagation();return _vm.createContentItem.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.__("Create Open Formtype")))]),_c('div',{staticStyle:{"width":"100%"}},[_c('el-main',{staticStyle:{"margin-top":"136px"}},[_c('el-form',{staticClass:"form-container"},[_c('PaginationToolbar',{attrs:{"sort-by-options":_vm.sortByOptions,"content-api":_vm.contentAPI},on:{"success":function($event){return _vm.$emit('replaceContent', $event)}}})],1),_c('el-scrollbar',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"native":true}},[_c('div',{staticClass:"contentCardsContainer"},[(_vm.openFormTypes.length)?_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.can('content.' + _vm.additionalValidateRoute + '.read')),expression:"can('content.' + additionalValidateRoute + '.read')"}],staticClass:"list-cards",attrs:{"type":"flex","justify":"start"}},_vm._l((_vm.openFormTypes),function(openFormType,index){return _c('ContentCard',{key:'_content_open_form_type_' + index,attrs:{"draggable-data":{
                content_id: _vm.selectedItems.length
                  ? _vm.selectedIds
                  : [openFormType[_vm.primaryKey]],
                content_type: _vm.contentType
              },"allow-multi-select":false,"card-class":{ selected: _vm.selectedItemIndex === index },"icon":"icon-open-form-grey.svg","card-text":openFormType.oft_name},on:{"delete":function($event){return _vm.handleDelete(
                  openFormType,
                  'OpenFormType',
                  openFormType.open_form_type_id
                )},"select":function($event){return _vm.handleSelect(index, openFormType)},"edit":function($event){return _vm.handleEdit(index, openFormType)}}})}),1):(!_vm.loading)?_c('el-row',{staticStyle:{"padding-top":"100px"},attrs:{"type":"flex","justify":"center"}},[_vm._v(" No Open Forms ")]):_vm._e()],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }